<template>
  <section class="content">
    <div class="inner content-grid">
      <div class="left" v-if="view_state == view_states.LIST">
        <page-heading heading="User Management"></page-heading>

        <h2>Please select a user to edit from the list below.</h2>

        <div class="hq-search">
          <input type="text" aria-label="Search" v-model.trim="user_filter" class="hq-search-text"
            placeholder="Search" />

          <button @click="addNew">Add New User</button>
          <button @click="_exportUsers" style="margin-right: 1rem;">Export Users</button>
        </div>

        <div class="hq-results-wrapper">
          <div class="hq-matches" v-if="!loading && filteredUsers && filteredUsers.length > 0">
            <div v-if="user_filter">
              Showing matches for search term "<strong>{{ user_filter }}</strong>":
            </div>
          </div>

          <div class="table-wrapper">
            <table v-if="!loading && filteredUsers && filteredUsers.length > 0" class="hq-accounts">
              <thead>
                <tr>
                  <td>Site</td>
                  <td>Email</td>
                  <td>Customer Ref</td>
                  <td class="desktop">Auth</td>
                  <td class="desktop">Last Login</td>
                  <td></td>
                  <td class="input-cell">
                    <font-awesome-icon icon="trash-alt" aria-hidden="true" role="presentation" />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in filteredUsers" :key="user.sub">
                  <td>
                    {{ user.site_name || user.account_number }}
                  </td>
                  <td class="email-cell">{{ user.properties._email }}</td>
                  <td>{{ user.customer_ref }}</td>
                  <td class="desktop">{{ user.properties["_custom:auth_type"] == 'Software' ? 'App' :
        user.properties["_custom:auth_type"] }}</td>
                  <td class="desktop">{{ formatDate(user.last_login_date) }}</td>
                  <td class="input-cell"><button @click="edit(user)">Edit</button></td>
                  <td class="input-cell"><input aria-label="Delete" type="checkbox" v-model="user.delete" /></td>
                </tr>
              </tbody>
            </table>
          </div>


          <div v-if="loading" class="hq-loading">
            <div><font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" /></div>
          </div>
          <div v-if="user_filter && !loading && filteredUsers.length < 1" class="hq-empty">
            <div>No matching users, please try a different search term</div>
          </div>

          <div v-if="!loading && deletableUsers.length" class="actions actions-centre">
            <button @click="showDeleteSelectedModal">
              Delete <img src="/images/icons/trash.png" alt="delete" />
            </button>
          </div>
        </div>
      </div>

      <div class="left" v-if="view_state == view_states.USEREDIT || view_state == view_states.USERADD">
        <page-heading heading="Edit User" v-if="view_state == view_states.USEREDIT"></page-heading>
        <page-heading heading="Add User" v-if="view_state == view_states.USERADD"></page-heading>

        <div class="inputs">
          <div class="input">
            <label id="lblAccountNumber">Account Number:</label>

            <input aria-labelledby="lblAccountNumber" class="txt" type="search" list="dlAccounts"
              v-model="user.account_number" />
            <datalist id="dlAccounts">
              <option v-for="account in accounts" :value="account.formattedAccountNumber">{{
        account.company + (account.customer_ref ? ` - ${account.customer_ref}` : '') }}</option>
            </datalist>
          </div>
          <div class="input">
            <label id="lblContactName">Contact Name:</label>
            <input aria-labelledby="lblContactName" type="text" class="txt" v-model.trim="user.properties.name" />
          </div>
          <div class="input" :class="{
        email_error:
          user.properties['custom:auth_type'] == 'Email' &&
          user.properties._email &&
          !$store.state.hq_user.domain.split(',').includes(user.properties._email.split('@')[1])
      }">
            <label id="lblEmail">Email:</label>
            <input aria-labelledby="lblEmail" v-tooltip="user.properties['custom:auth_type'] == 'Email' &&
        user.properties._email &&
        !$store.state.hq_user.domain.split(',').includes(user.properties._email.split('@')[1])
        ? 'Invalid email domain for email authentication'
        : ''
        " type="email" class="txt" v-model.trim="user.properties.email" />
          </div>
          <div class="input">
            <label id="lblTelephone">Telephone:</label>
            <input aria-labelledby="lblTelephone" type="email" class="txt"
              v-model.trim="user.properties['custom:phone']" />
          </div>
          <!-- <div class="input" v-if="view_state == view_states.USEREDIT && user.properties._status !== 'Pending Approval'">
            <label>Locked Out:</label>
            <input type="checkbox" class="chk" v-model="user.properties.locked_out" />
          </div> -->
          <div class="input">
            <label for="radSMS" style="opacity: 0.6">Authentication by:</label>
          </div>
          <div class="input">
            <label for="radSMS">SMS</label>
            <input type="radio" id="radSMS" class="chk" v-model="user.properties['custom:auth_type']" value="SMS" />
          </div>
          <div class="input" style="padding-top: 0">
            <label for="radSoftwareToken">App</label>
            <input type="radio" id="radSoftwareToken" class="chk" v-model="user.properties['custom:auth_type']"
              value="Software" />
          </div>
          <div class="input" style="padding-top: 0">
            <label for="radEmail">Email</label>
            <input type="radio" id="radEmail" class="chk" :disabled="!this.$store.state.hq_user.email_authentication"
              v-model="user.properties['custom:auth_type']" value="Email" />
          </div>
        </div>

        <error-panel v-if="error_message" :content="error_message" style="width: 80rem"></error-panel>

        <div class="actions inputs">
          <div style="margin-bottom: 1rem;">
            <button @click="cancelEdit" class="negative">
              Cancel <img src="/images/icons/button-icon-cancel.png" alt="Cancel" />
            </button>
            <button @click="save" class="step9">
              <template v-if="save_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-else> Save <img src="/images/icons/button-icon-submit.png" alt="save" /> </template>
            </button>
          </div>
          <div>
            <button @click="resetPassword">
              <template v-if="reset_loading">
                <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
              </template>
              <template v-else>
                Reset password
              </template>
            </button>
            <button @click="showDeleteModal">
              Delete <img src="/images/icons/trash-w.png" alt="delete" />
            </button>
          </div>
        </div>
      </div>

      <right-column></right-column>
    </div>

    <modal :scrollable="true" height="auto" name="delete-user" width="90%">
      <a @click.prevent="$modal.hide('delete-user')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <h2>Please confirm</h2>
      <p>Are you sure you want to delete this user?</p>
      <p class="error" v-if="error_message">{{ error_message }}</p>
      <div class="actions">
        <button @click="_delete">
          <template v-if="delete_loading">
            <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
          </template>
          <template v-else>
            <font-awesome-icon icon="check" /> Yes
          </template>
        </button>
        <button class="negative" @click="$modal.hide('delete-user')"><font-awesome-icon icon="times" /> No</button>
      </div>
    </modal>

    <modal :scrollable="true" height="auto" name="delete-users" width="90%">
      <a @click.prevent="$modal.hide('delete-user')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <h2>Please confirm</h2>
      <p v-if="deletableUsers.length == 1">Are you sure you want to delete this user?</p>
      <p v-else>Are you sure you want to delete these {{ deletableUsers.length }} users?</p>
      <p class="error" v-if="error_message">{{ error_message }}</p>
      <div class="actions">
        <button @click="deleteSelected">
          <template v-if="delete_loading">
            <font-awesome-icon icon="circle-notch" class="fa-spin" aria-label="Loading" role="status" />
          </template>
          <template v-else>
            <font-awesome-icon icon="check" /> Yes
          </template>
        </button>
        <button class="negative" @click="$modal.hide('delete-users')"><font-awesome-icon icon="times" /> No</button>
      </div>
    </modal>
  </section>
</template>

<script>
import api from "@/services/api"
import { formatAccountNumber, reverseFormatAccountNumber } from "@/util/vars"

export default {
  data() {
    var view_states = {
      LIST: "List",
      USEREDIT: "UserEdit",
      USERADD: "UserAdd"
    }

    return {
      loading: true,
      save_loading: false,
      delete_loading: false,
      reset_loading: false,
      view_states,
      user: null,
      error_message: "",
      accounts: [],

      letters: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      ],

      users: [],
      user_filter: ""
    }
  },
  computed: {
    view_state: {
      get() {
        return this.$store.state.user_management_view_state
      },
      set(value) {
        this.$store.commit('setUserManagementViewState', value)
      }
    },
    filteredUsers() {
      if (!this.user_filter) return this.users

      let account_filter = this.user_filter;

      if (this.user_filter && this.user_filter.startsWith("02")) {
        const letterIndex = this.letters.indexOf(this.user_filter.substring(2, 3).toUpperCase());

        if (letterIndex > -1) {
          account_filter = this.user_filter.substring(0, 2) +
            (letterIndex + 1).toString().padStart(2, "0") +
            this.user_filter.substring(3);
        }
      }

      return this.users.filter(user =>
        (user._account_number && user._account_number.includes(account_filter)) ||
        (user.properties._email && user.properties._email.toLowerCase().includes(this.user_filter.toLowerCase())) ||
        (user.site_name && user.site_name.toLowerCase().includes(this.user_filter.toLowerCase())) ||
        (user.customer_ref && user.customer_ref.toLowerCase().includes(this.user_filter.toLowerCase()))
      );
    },
    deletableUsers() {
      return this.filteredUsers.filter(user => user.delete === true);
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return ''

      const dateObj = new Date(dateString);
      const formattedDate = dateObj.toLocaleDateString('en-GB');

      return formattedDate;
    },
    getUsers() {
      this.loading = true
      api
        .request("get", "user-hq?type=site-users")
        .then(res => {
          var users = res.users

          users.forEach(user => {
            user.account_number = this.formatAccNo(user.account_number)
            user._account_number = user.account_number
          })

          this.users = users
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    addNew() {
      this.user = {
        properties: {
          ["_custom:auth_type"]: "Software",
          ["custom:auth_type"]: "Software",
        }
      }

      this.view_state = this.view_states.USERADD
    },
    edit(user) {
      this.user = user
      this.view_state = this.view_states.USEREDIT
    },
    showDeleteModal() {
      this.$modal.show("delete-user")
    },
    showDeleteSelectedModal() {
      this.$modal.show("delete-users")
    },
    _delete() {
      this.delete_loading = true

      api
        .request("delete", "user-hq?type=site-user&id=" + this.user.properties.sub)
        .then(res => {
          this.delete_loading = false
          this.$modal.hide("delete-user")
          this.view_state = this.view_states.LIST
          this.getUsers()
        })
        .catch((err) => {
          this.delete_loading = false

          this.error_message = "There was an error, please try again"
        })
    },
    deleteSelected() {
      this.delete_loading = true

      let p = []

      let deletePromises = this.deletableUsers.map(user => {
        return api.request("delete", "user-hq?type=site-user&id=" + user.properties.sub)
          .then(() => ({ status: 'success', user }))
          .catch(() => ({ status: 'error', user }));
      });

      Promise.allSettled(deletePromises).then(results => {
        this.delete_loading = false
        this.$modal.hide("delete-users")
        this.getUsers()

        let errors = results.filter(r => r.status === 'rejected' || (r.value && r.value.status === 'error'));
        if (errors.length > 0) {
          this.error_message = "Some deletions failed: " + errors.map(e => e.value.user.properties._email).join(", ");
        } else {
          this.view_state = this.view_states.LIST;
        }
      })
    },
    cancelEdit() {
      Object.keys(this.user.properties).forEach(key => {
        if (key.startsWith('_')) {
          let newKey = key.substring(1);
          this.user.properties[newKey] = this.user.properties[key];
        }
      });

      this.error_message = ""
      this.view_state = this.view_states.LIST
    },
    save() {
      if (this.view_state == this.view_states.USERADD) this.saveNew()
      if (this.view_state == this.view_states.USEREDIT) this.saveExisting()
    },
    saveExisting() {
      this.save_loading = true

      this.error_message = ""

      if (!this.user.properties.name || !this.user.properties.email || !this.user.properties["custom:phone"]) {
        this.error_message = "Please fill in all fields"
        this.save_loading = false
        return
      }

      if (this.user.properties["custom:auth_type"] == "Email") {
        if (!this.$store.state.hq_user.domain.split(",").includes(this.user.properties.email.split("@")[1])) {
          this.error_message = "Incorrect domain for email authentication"
          this.save_loading = false
          return
        }
      }


      var userToSend = JSON.parse(JSON.stringify(this.user))
      userToSend.account_number = this.reverseFormatAccNo(userToSend.account_number)
      userToSend._account_number = this.reverseFormatAccNo(userToSend._account_number)

      api
        .request("put", "user-hq?type=site-user", { user: userToSend })
        .then(res => {
          this.save_loading = false
          this.view_state = this.view_states.LIST
          this.getUsers()
        })
        .catch((err) => {
          this.save_loading = false

          let error = err.response.data

          switch (error.code) {
            case "AliasExistsException":
              this.error_message = error.message
              break
            case "InvalidParameterException":
              this.error_message = error.message
              break
            default:
              this.error_message = "There was an error, please try again"
          }
        })


    },
    saveNew() {
      this.save_loading = true

      this.error_message = ""

      if (!this.user.account_number || !this.user.properties.name || !this.user.properties.email || !this.user.properties["custom:phone"]) {
        this.error_message = "Please fill in all fields"
        this.disable = false
        return
      }

      if (!this.accounts.map(a => a.formattedAccountNumber).includes(this.user.account_number)) {
        this.error_message = "Please select a valid account number"
        this.disable = false
        return
      }

      if (this.user.properties["custom:auth_type"] == "Email") {
        if (!this.$store.state.hq_user.domain.split(",").includes(this.user.properties.email.split("@")[1])) {
          this.error_message = "Incorrect domain for email authentication"
          this.save_loading = false
          return
        }
      }

      var userToSend = JSON.parse(JSON.stringify(this.user))
      userToSend.account_number = this.reverseFormatAccNo(userToSend.account_number)
      userToSend._account_number = this.reverseFormatAccNo(userToSend._account_number)

      api
        .request("post", "user-hq?type=site-user", { user: userToSend })
        .then(res => {
          this.save_loading = false
          this.view_state = this.view_states.LIST
          this.getUsers()
        })
        .catch(err => {
          this.save_loading = false

          let error = err.response.data

          switch (error.code) {
            case "UsernameExistsException":
              this.error_message = error.message
              break
            case "InvalidParameterException":
              this.error_message = error.message
              break
            default:
              this.error_message = "There was an error, please try again"
          }
        })
    },
    resetPassword() {
      this.reset_loading = true

      this.error_message = ""

      var userToSend = JSON.parse(JSON.stringify(this.user))
      userToSend.account_number = this.reverseFormatAccNo(userToSend.account_number)
      userToSend._account_number = this.reverseFormatAccNo(userToSend._account_number)

      api
        .request("patch", "user-hq?type=site-user-password", { user: userToSend })
        .then(() => {
          this.view_state = this.view_states.LIST
          this.getUsers()
        })
        .catch(() => {
          this.error_message = "There was an error, please try again"
        })
        .finally(() => {
          this.reset_loading = false
        })
    },
    _exportUsers() {
      this.export_users_loading = true


      var csvContent = "Site number, Site name, Email, Telephone, Customer ref, Authentication Type, Last Login\n"

      this.filteredUsers.forEach(item => {
        csvContent +=
          [
            '"' + item.account_number + '"',
            '"' + (item.site_name || "") + '"',
            '"' + item.properties._email + '"',
            '"=""' + item.properties["_custom:phone"] + '"""',
            '"' + (item.customer_ref || "") + '"',
            '"' + item.properties["_custom:auth_type"] + '"',
            '"' + this.formatDate(item.last_login_date) + '"'
          ].join(",") + "\n"
      })

      var blob = new Blob([csvContent], { type: "text/csv" })
      var url = window.URL.createObjectURL(blob)
      var a = document.createElement("a")
      a.href = url
      a.download = "Users.csv"
      a.click()
    },
    formatAccNo(acc_no) {
      return formatAccountNumber(acc_no)
    },
    reverseFormatAccNo(acc_no) {
      return reverseFormatAccountNumber(acc_no)
    }
  },
  created() {
    this.view_state = this.view_states.LIST

    this.getUsers()

    api.request("get", "account?all=true").then(res => {
      res.forEach(a => (a.formattedAccountNumber = this.formatAccNo(a.account_number)))

      this.accounts = res
    })
  },
}
</script>

<style scoped>
input[type="checkbox"]:focus-visible,
input[type="radio"]:focus-visible {
  outline: 2px solid #CB4E0B !important;
  outline-offset: 2px;
}

.input span {
  align-self: center;

}

.table-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90rem;
}

.hq-accounts {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;
}

.hq-accounts td {
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.hq-accounts thead td {
  background: #eee;
  font-weight: bold;
}

.hq-accounts tr:nth-child(even) td {
  background: #f8f8f8;
}

.hq-search button,
.hq-accounts button {
  background: #ca0028;
  border: 0;
  padding: 0.5rem 2.5rem;
  border-radius: 2rem;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
}

.hq-search button {
  float: right;
  font-size: 2rem;
  padding: 1rem 2rem;
}

.hq-accounts button:hover {
  background: #b80125;
}

.email-cell {
  max-width: 33rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hq-search-text {
  width: 100%;
  max-width: 40rem;
  margin-bottom: 3rem;
  /* background-image: url("/images/icons/field-icon-filter.png") !important; */
  background-position: 96% center !important;
  background-size: 3rem !important;
  background-repeat: no-repeat !important;
  padding-right: 5rem;
  border: 1px solid #ccc;
  padding: 1rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
}

.hq-search-text::placeholder {
  color: rgb(204, 204, 204);
}

.hq-search-text:focus {
  border: 1px solid #29383f;
}

.hq-loading {
  display: flex;
  align-items: center;
  min-height: 38rem;
  font-size: 3rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
}

.hq-loading>div {
  width: 100%;
  display: block;
  text-align: center;
}

.hq-more {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.8;
}

.hq-matches {
  padding-top: 0;
  padding-bottom: 2rem;
  font-size: 1.4rem;
  opacity: 0.8;
}


.input-cell {
  text-align: center;
  vertical-align: middle;
}
</style>
